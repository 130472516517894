import React, { useMemo } from 'react';
import { Link } from 'gatsby';

import './Article.scss';
import './SectionBlock.scss';

interface BasicCtASettings {
  link: string;
  text: string;
}
type CtASettings = BasicCtASettings | React.ComponentType;
interface SectionBlockParameters {
  image?: {
    url: string;
    alt: string;
    beforeText?: boolean;
    fullSize?: boolean;
  };
  title?: string;
  text: string;
  hasNoPadding?: boolean;
  cta?: CtASettings;
  className?: string;
  children: Array<React.Component>;
}

function isBasicCtA(a: any): a is BasicCtASettings {
  return a && a.link && a.text;
}
const BasicCtA = (cta: { url: string; text: string }) => (
  <div className="th-section-block-cta">
    <Link className="th-section-block-cta__anchor" to={cta.url}>
      {cta.text}
    </Link>
  </div>
);
const ImageBlock = (props: { fullSize: boolean; image: string; alt: string; position: string }) => (
  <div className={props.fullSize ? 'col-lg-12' : 'col-lg-5'}>
    <div className="th-article-content__image">
      <img src={props.image} alt={props.alt} />
    </div>
  </div>
);
const TextBlock = (props: {
  cta?: CtASettings;
  title?: string;
  fullSize: boolean;
  children: Array<React.Component>;
}) => {
  return (
    <div key="colText" className={props.fullSize ? 'col-lg-12' : 'col-lg-7'}>
      <div className="th-article-text">
        {props.title && (
          <h3 className="th-article-content__header th-article-content__header-decorated">{props.title}</h3>
        )}
        {props.children}
        {props.cta && isBasicCtA(props.cta) && <BasicCtA url={props.cta.link} text={props.cta.text} />}
      </div>
    </div>
  );
};
const NewSectionBlock = ({ image, ...props }: SectionBlockParameters) => {
  const isTextBlockFullSize = React.useMemo(() => {
    if (image) {
      return !!image.fullSize;
    }
    return true;
  }, [image]);

  return (
    <div className={`th-article-content${props.className ? ` th-article-content${props.className}` : ''}`}>
      <div className={`th-article-main th-article-main${props.hasNoPadding ? '--no-padding' : ''}`}>
        <div className="container">
          <div className="row no-gutters">
            {image && image.beforeText && (
              <ImageBlock fullSize={!!image.fullSize} image={image.url} alt={image.alt} position="left" />
            )}
            <TextBlock fullSize={isTextBlockFullSize} title={props.title} cta={props.cta}>
              {props.children}
            </TextBlock>
            {image && !image.beforeText && (
              <ImageBlock fullSize={!!image.fullSize} image={image.url} alt={image.alt} position="right" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewSectionBlock;
