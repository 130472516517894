import React from 'react';

import SectionBlock from '../components/Block/NewSectionBlock';

import './digital-transformation.scss';

const TelenorPage = () => (
  <>
    <div className="th-hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">Telenor Case Study</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SectionBlock title={false} image={false}>
      <p>
        One of the most significant projects we worked on in 2019 and 2020 was for Telenor, Norway’s largest provider of
        tele, data and media communication services. With operations across different markets, they are one of the
        world’s largest mobile telecommunications companies.
      </p>
    </SectionBlock>

    <SectionBlock title="Help needed" image={{ fullSize: false, url: '/usecase/telenor_app_main.jpg' }}>
      <p>
        The idea came from a few members of Telenor’s Consumer IoT team who love dogs and realised there was a gap in
        the market. There are 600 000 dogs in Norway alone, and almost 88 million across the EU. And while dogs are
        becoming more and more popular every day, there is not much out there that assuages a dog owner's greatest fear
        – their beloved pet tearing off after a stray cat or terrified by fireworks and not coming back.
      </p>
      <p>
        In Norway, this problem is somewhat mitigated by the obligatory microchipping, but even with this technology in
        place a large number of runaway dogs are never found since the microchip in essence serves more to track the
        owner of the lost pet, rather than the other way around. Not only that, but the microchip can only be read by
        specialised technology as they transmit on a special NFC carrier frequency which complicates the localisation
        process even further.
      </p>
    </SectionBlock>

    <SectionBlock title="Enter SolveQ" image={{ fullSize: false, url: '/usecase/telenor_app_led_indications.jpg' }}>
      <p>
        Working closely with the Telenor team, an innovative solution to this growing problem was developed, in order to
        help keep everyone’s pets safe. As part of our IoT experience, we created a mobile application that connects to
        GPS trackers located on dog collars, thus enabling the owners to check where their pets are at all times. Our
        team at SolveQ created the infrastructure and programmed the trackers to communicate with the application and
        send real time information about the tracker’s location.
      </p>
      <p>
        We had to put together a team for this project on very short notice, which required strategic planning of our
        available resources and restructuring our ongoing projects, but we’re nothing if not flexible. Once we had the
        team in place, we were able to take on the challenge!
      </p>
    </SectionBlock>

    <SectionBlock title="The tracker" image={{ fullSize: false, url: '/usecase/telenor_app_share_tracker.jpg' }}>
      <p>
        Eventually we completed the project, resulting in an innovative pet tracker that was wireless, durable,
        waterproof and seamlessly integrated with the GPS tracking system. The trackers communicate using LTE-M network
        which allows for rapid data transfer as well as Bluetooth for certain operations. It’s based on nrf52 SoC,
        provides access to the developers to a LTE-M/4G combo modem (U-blox SARA R410), GPS (U-blox SARA M8Q),
        accelerometer and Qi charging.
      </p>
    </SectionBlock>

    <SectionBlock title="Backend and infrastructure" image={false}>
      <p>
        In order to be able to expand the platform to other use-cases and devices later we built a structure that
        enables various trackers (even not of the specific type created for this project) to use the platform and to be
        tracked. Infrastructure-as-code improvements were put in place to help streamline development and deployment of
        the platform.
      </p>
    </SectionBlock>

    <SectionBlock title="Frontend" image={{ fullSize: false, url: '/usecase/telenor_app_my_walks.jpg' }}>
      <p>
        The mobile app is built using the ReactNative framework in TypeScript language. It communicates with the backend
        mainly via WebSockets to ensure that users view the data in real-time. Additionally, it communicates directly
        with the tracker devices over BLE (Bluetooth Low Energy) protocol if the device is in phone range. The trackers
        can be shared across users, as implemented through Firebase Dynamic Links.
      </p>
      <p>
        Besides the main feature of the app (being able to locate your dog), users can register the route of the walk
        and later browse walk statistics.
      </p>
    </SectionBlock>

    <SectionBlock title="Quality delivered" image={{ fullSize: false, url: '/usecase/telenor_app_position.jpg' }}>
      <p>
        Ultimately we were able to devise and implement a low-power communication protocol so devices did not need to
        persistently communicate with the backend; also, implementing buffering & batching on the tracker side ensured
        that the trackers could sustain a long disconnect from mobile network while losing no data. We completed
        multiple battery-saving optimizations so that trackers would last longer - the battery life was extended from 6
        hours of use to 12+ hours. We improved the processes allowing the companion app to check the state of the
        tracker and implemented direct feedback mechanisms so the application could trigger a mode change in the tracker
        without requiring backend input.
      </p>
    </SectionBlock>

    <div className="th-article-content">
      <div className="th-article-main th-article-main__intro">
        <div className="container">
          <div className="row">
            <p>
              <em>
                We are very satisfied with SolveQ and especially with the team working together with us on the Mitt Spor
                product.
              </em>
            </p>
            <p>
              <em>
                The team works efficiently and delivers as expected, often beyond our expectations. They are always
                forthcoming to accommodate any new requests and ready to assist if any issue occurs.
              </em>
            </p>
            <p>
              <em>
                Importantly, the team is also proactive and providing us with expert advice [on] how to develop the most
                robust, stable and flexible solution for the future.
              </em>
            </p>

            <p>
              <em>
                The team members are experienced and professional, and at the same time down to earth and easy to work
                closely together with.
              </em>
            </p>

            <p>
              <cite><strong>- Rune Skogly Nilsen, Mitt Spor Product Owner, Telenor Mobile</strong></cite>
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);
export default TelenorPage;
